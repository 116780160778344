<template>
  <v-row>
    <!-- Start Single Portfolio  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--40"
      v-for="(item, i) in portfolioContent"
      :key="i"
    >
      <div class="im_portfolio text-center">
        <div class="thumbnail_inner">
          <div class="thumbnail">
            <a :href="item.to">
              <img class="w-100" :src="item.src" alt="Portfolio Images" />
            </a>
          </div>
        </div>
        <div class="content">
          <div class="inner">
            <div class="portfolio_heading">
              <div class="category_list">
                <router-link :to="item.to">{{ item.category }}</router-link>
              </div>
              <h4 class="heading-title">
                <router-link :to="item.to">{{ item.title }}</router-link>
              </h4>
            </div>
          </div>
        </div>
        <router-link class="transparent_link" :to="item.to"></router-link>
      </div>
    </v-col>
    <!-- End Single Portfolio  -->
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      portfolioContent: [
        {
          src: require("../../assets/images/colors/color1.jpg"),
          category: "Module 2 Assignment",
          title: "Learning Experience Design Statement",
          to: "/LDTE5210/design-statement",
        },
        // {
        //   src: require("../../assets/images/colors/color2.jpg"),
        //   category: "Module 2",
        //   title: "Assignment Name",
        //   to: "/LDTE5020",
        // },
        // {
        //   src: require("../../assets/images/colors/color3.jpg"),
        //   category: "Module 3",
        //   title: "Assignment Name",
        //   to: "/LDTE5020",
        // },
        // {
        //   src: require("../../assets/images/colors/color4.jpg"),
        //   category: "Module 4",
        //   title: "Assignment Name",
        //   to: "/LDTE5020",
        // },
        // {
        //   src: require("../../assets/images/colors/color5.jpg"),
        //   category: "Module 5",
        //   title: "Assignment Name",
        //   to: "/LDTE5020",
        // },
        // {
        //   src: require("../../assets/images/colors/color1.jpg"),
        //   category: "Module 6",
        //   title: "Assignment Name",
        //   to: "/LDTE5020",
        // },
      ],
    };
  },
};
</script>
